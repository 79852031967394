// Thanks to https://samuelkraft.com/blog/spring-parallax-framer-motion-guide

import React, { useRef, useLayoutEffect, useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import {
  motion,
  useTransform,
  useSpring,
  useScroll,
  MotionValue,
} from 'framer-motion';
import { useWindowSize } from './useWindowSize';
import isBrowser from '../lib/isBrowser';

const Parallax = ({
  children,
  offset = 50,
  xOffset = 0,
  zIndex = 1,
  style,
}) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const yVal = useParallax(scrollYProgress, offset);
  const xVal = useParallax(scrollYProgress, xOffset);

  const y = useSpring(yVal, { stiffness: 400, damping: 90 });
  const x = useSpring(xVal, { stiffness: 400, damping: 90 });

  return (
    <div style={style} ref={ref}>
      <motion.div
        style={{
          y,
          x,
          zIndex,
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};

Parallax.propTypes = {
  children: PropTypes.node,
  offset: PropTypes.number,
  xOffset: PropTypes.number,
  zIndex: PropTypes.any,
  style: PropTypes.object,
};

export default memo(Parallax);

function useParallax(value: MotionValue<number>, distance: number) {
  return useTransform(value, [0, 1], [-distance, distance]);
}
