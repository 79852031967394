import React from 'react';

import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import Parallax from '../Parallax';
import { LazyMotion } from 'framer-motion';

const loadFeatures = () =>
  import('../../lib/framer/motionMaxFeatures.js').then((res) => res.default);

export default function DynamicParallax({ blok }) {
  const { offset, xOffset, zIndex, style } = blok;
  const styleProps = style ? JSON.parse(style) : {};
  return (
    <LazyMotion strict features={loadFeatures}>
      <Parallax
        {...storyblokEditable(blok)}
        offset={offset}
        xOffset={xOffset}
        zIndex={zIndex}
        style={styleProps}
      >
        {blok.items.map((blok) => (
          <StoryblokComponent key={blok.uuid} blok={blok} />
        ))}
      </Parallax>
    </LazyMotion>
  );
}
